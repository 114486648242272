import Link from "next/link";
import type { ReactNode } from "react";
import React from "react";
import { twMerge } from "tailwind-merge";

interface ButtonProps {
  style?: "default" | "primary" | "dotted" | "transparent";
  outline?: boolean;
  disabled?: boolean;
  inactive?: boolean;
  children?: ReactNode;
  tooltip?: string;
  // https://developer.mozilla.org/en-US/docs/Web/HTML/Element/button#attr-type
  type?: "submit" | "button" | "reset";

  /** Provide tailwind style className props */
  className?: string;

  href: string;
}

/**
 * Props:
 *
 * `style` -- Color of the Anchor
 *
 * `outline` -- Outline Anchor text instead of solid bg color
 *
 * `disabled` -- Whether or not this Anchor is disabled
 *
 * `inactive` -- A non-active Anchor
 *
 * `tooltip` -- Helptext to display
 *
 * `onClick` -- Action function to call when the Anchor is clicked
 *
 * `type` -- 'submit', 'Anchor', or 'reset'. See: https://developer.mozilla.org/en-US/docs/Web/HTML/Element/button#attr-type
 *
 */
export default function GeneralAnchor({
  style = "default",
  outline = false,
  disabled = false,
  inactive = false,
  href,
  tooltip,
  children,
  type,
  className,
  target,
}: ButtonProps &
  React.HTMLProps<HTMLButtonElement> &
  React.HTMLProps<HTMLAnchorElement>) {
  const getBackgroundColor = () => {
    switch (style) {
      case "primary":
        return "bg-hmio-blue-500";
      case "dotted":
        return "bg-hmio-black-700";
      case "transparent":
        return "bg-hmio-black-DEFAULT";
      default:
        return "bg-hmio-black-700";
    }
  };

  const getHoverColor = () => {
    switch (style) {
      case "primary":
        return outline
          ? "hover:shadow-hmio-blue-600"
          : "hover:bg-hmio-blue-600";
      default:
        return "hover:opacity-80";
    }
  };

  const getOutlineColor = () => {
    switch (style) {
      case "primary":
        return "shadow-inner-border-1 shadow-hmio-blue-500";
      case "dotted":
        return "border-dashed	border-hmio-black-700 border";
      default:
        return "shadow-inner-border-1 shadow-hmio-black-700";
    }
  };

  const getState = () => {
    if (inactive) return "bg-hmio-black-900 text-hmio-black-400";
    if (disabled) return "opacity-30 cursor-not-allowed";
    return `${getHoverColor()} hover:ease-linear duration-75`;
  };

  const mergedClassName = twMerge(
    `
      select-noneselect-none
      inline-block
      rounded-full 
      text-center
      text-hmio-white 
      text-sm 
      font-medium 
      py-2.5 
      px-6
      cursor-pointer
    `,
    className,
  );

  return (
    <Link href={href}>
      <a
        title={tooltip}
        type={type}
        className={`
        ${mergedClassName}
        ${outline ? getOutlineColor() : getBackgroundColor()}
        ${getState()}
        `}
        target={target}
      >
        {children}
      </a>
    </Link>
  );
}
