import type { Testimonial } from "model/testimonial/testimonial.interface";
import { useContext, useEffect, useState } from "react";
import { DynamoConfigContext, DynamoEnvKeys } from "src/context/configContext";

export const useTestimonials = (): Testimonial[] => {
  const [testimonials, setTestimonials] = useState<Testimonial[]>([]);

  const configContext = useContext(DynamoConfigContext);

  useEffect(() => {
    const config = configContext?.config;
    const tweets = config?.find(
      (configEle) => configEle.key.S === DynamoEnvKeys.DEV_LANDING_TESTIMONIALS,
    );
    // Parse tweets or parse an empty array if there are none
    const parsedTweets = JSON.parse(tweets?.value.S ?? "[]");
    setTestimonials(parsedTweets);
  }, [configContext]);

  return testimonials;
};
