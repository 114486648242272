import { useState } from "react";
import { CarouselProps } from "./types";
import { AspectRatio } from "@radix-ui/react-aspect-ratio";
import Image from "next/image";

/** Modeled after DevTools section on: https://www.alchemy.com/ */
export default function DesktopCarousel({ slides, className }: CarouselProps) {
  // Slide index
  const [current, setCurrent] = useState<number>(0);

  // Placeholder colors
  const colors = [
    "bg-hmio-black-700",
    "bg-hmio-green",
    "bg-hmio-blue",
    "bg-hmio-red",
    "bg-hmio-yellow",
  ];

  return (
    <div className={className}>
      <div className="flex gap-20 max-w-[1008px] mx-auto justify-between">
        <div className="max-w-[550px]">
          <ol>
            {slides.map((slide, idx) => (
              <li
                role="button"
                onClick={() => setCurrent(idx)}
                key={`slide-text-${idx}`}
                className={`group px-6 mb-8 last:mb-0 cursor-pointer duration-200 ${
                  current === idx
                    ? "is-active bg-hmio-black-900 rounded-2xl py-6 cursor-auto"
                    : ""
                }`}
              >
                <h3 className="text-hmio-black-400 group-[.is-active]:text-hmio-black-100">
                  {slide.title}
                </h3>
                <p className="hidden mt-4 text-hmio-black-400 group-[.is-active]:block text-base group-[.is-active]:animate-fade-in">
                  {slide.description}
                </p>
              </li>
            ))}
          </ol>
        </div>
        <div className="w-[448px] h-[448px] rounded-2xl shrink-0 overflow-hidden">
          <div className="flex w-full overflow-hidden flex-col">
            {slides.map((slide, idx) => (
              <div
                key={`slide-image-${idx}`}
                className={`${
                  !slide.imageUrl ? colors[idx] : "bg-transparent"
                } animate-fade-in ${current !== idx ? "hidden" : ""}`}
              >
                {/* <AspectRatio className={`bg-hmio-black-700`} ratio={1 / 1} /> */}
                <AspectRatio ratio={1 / 1}>
                  {/* TODO alt text */}
                  {slide.imageUrl ? (
                    <Image src={slide.imageUrl} alt="" layout="fill" />
                  ) : null}
                </AspectRatio>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
