import { PartnerLogo } from "model/partnerLogo/partnerLogo.interface";
import Image from "next/image";
import { usePartnerLogos } from "src/hooks/usePartnerLogos";

const Logos = () => {
  // Two divs are used below to create the effect of infinite horizontal scrolling
  const logos = usePartnerLogos();
  return (
    <div className="animate-marquee gap-[115px] flex w-fit">
      <div className="flex justify-center gap-[115px] items-center">
        {logos.map((l, idx) => (
          <div key={idx} style={{ height: l.height, width: l.width }}>
            <Image src={l.url} height={l.height} width={l.width} />
          </div>
        ))}
      </div>
      <div className="flex gap-[115px] justify-center items-center">
        {logos.map((l, idx) => (
          <div key={idx} style={{ height: l.height, width: l.width }}>
            <Image src={l.url} height={l.height} width={l.width} />
          </div>
        ))}
      </div>
    </div>
  );
};

const Marquee = () => {
  return (
    <div className="relative max-w-full overflow-hidden">
      <div className="hidden lg:block absolute top-0 left-0 h-full w-[250px] z-10 bg-gradient-to-r from-hmio-black" />
      <div className="hidden lg:block absolute top-0 right-0 h-full w-[250px] z-10 bg-gradient-to-l from-hmio-black" />
      <div className="px-6 overflow-hidden">
        <Logos />
      </div>
    </div>
  );
};

export default Marquee;
