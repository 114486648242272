import Layout from "components/Layouts/layout";
import Marquee from "components/Marquee/Marquee";
import { NextPageWithLayout } from "pages/_app";
import { ReactElement } from "react";
import { Routes } from "src/routes/appRoutes";
import GeneralAnchor from "components/Anchor/GenericAnchor";
import PricingPlans from "components/Leaderboard/Developer/PricingPlan/PricingPlans";
import { twMerge } from "tailwind-merge";
import MobileCarousel from "components/Carousel/MobileCarousel";
import DesktopCarousel from "components/Carousel/DesktopCarousel";
import Tweet from "components/Tweet/Tweet";
import Rotisserie from "components/Carousel/Rotisserie";
import Button from "components/Button/Button";
import TwitterLogo from "src/icons/V2/Social/Twitter.svg";
import DiscordLogo from "src/icons/V2/Social/Discord.svg";
import Anchor from "components/Anchor/Anchor";
import { useTestimonials } from "src/hooks/useTestimonials";

// One off manual syntax highlighting for the landing page...
const DeveloperCodeBlock = () => {
  // CSS classes
  const line = "group overflow-hidden max-w-full whitespace-nowrap";
  const lineNumber =
    "pl-4 pr-2 mr-3 inline-block bg-hmio-black-900 text-hmio-black-400 group-first:pt-4 group-last:pb-4";
  const flag = "text-[#bb7cd7]"; // Purple
  const command = "text-[#f7cd7a]"; // Yellow
  const stringArg = "text-[#a1c281]"; // Green

  return (
    <div className="overflow-auto rounded-lg grow md:max-w-[480px] md:min-w-[350px] font-mono bg-hmio-black text-[#a7adb9]">
      <div className={line}>
        <span className={lineNumber}>1</span>
        <span className={command}>curl</span>{" "}
        <span className={flag}>--request</span> POST \
      </div>
      <div className={line}>
        <span className={lineNumber}>2</span>
        <span className="whitespace-pre">{"\t"}</span>
        <span className={flag}>--url</span> https://rest-api.hellomoon.io/v0
      </div>
      <div className={line}>
        <span className={lineNumber}>3</span>
        <span className="whitespace-pre">{"\t\t"}</span>{" "}
        collection_listing_candlesticks/id
      </div>
      <div className={line}>
        <span className={lineNumber}>4</span>
        <span className="whitespace-pre">{"\t"}</span>
        <span className={flag}>--header</span>{" "}
        <span className={stringArg}>
          &lsquo;accept: application/json&rsquo;
        </span>{" "}
        \
      </div>
      <div className={line}>
        <span className={lineNumber}>5</span>
        <span className="whitespace-pre">{"\t"}</span>
        <span className={flag}>--header</span>{" "}
        <span className={stringArg}>
          &lsquo;content-type: application/json&rsquo;
        </span>{" "}
        \
      </div>
    </div>
  );
};

const Section = ({ id, className, children }: React.HTMLProps<HTMLElement>) => (
  <section id={id} className={twMerge("px-6 mb-14 md:mb-32", className)}>
    {children}
  </section>
);

const toolsSlides = [
  {
    title: "Real-time Solana NFT and DeFi data",
    description:
      "Integrate the most up-to-date and accurate data from across the Solana ecosystem.",
    imageUrl: "/copy/Realtime.png",
  },
  {
    title: "No code datastream builder",
    description:
      "Easily build and customize your own datastreams in a few minutes without writing a single line of code.",
    imageUrl: "/copy/nocode.gif",
  },
  {
    title: "Flexible delivery methods",
    description:
      "Choose how you want your data delivered - REST API, webhook or websocket",
    imageUrl: "/copy/Delivery.png",
  },
];

const DevelopersPage: NextPageWithLayout = () => {
  const tweets = useTestimonials();
  return (
    <>
      <Section>
        <div className="mt-[228px] md-max:mt-16" />
        <section className="flex flex-col items-center justify-center">
          <header className="flex flex-col items-center justify-center font-bold md-max:text-3.5xl text-5.5xl h-[144px] text-hmio-black-100 font-Inter ">
            <span className="break-words md:w-[800px] md-max:w-[300px] text-center">
              Infrastructure to build the future of Solana
            </span>
          </header>
          <div className="mt-8" />
          <h5 className="break-words md-max:w-[300px] text-center text-hmio-black-400">
            Harness the most powerful Solana developer tools to build and scale
            your business
          </h5>
          <div className="mt-14" />
          <div className="flex flex-row items-center justify-center md-max:flex-col">
            <GeneralAnchor
              href={Routes.dashboard}
              style="primary"
              className="w-48 h-10 "
            >
              Start building for free
            </GeneralAnchor>
            <div className="ml-10 md-max:mt-[22px] " />
            <GeneralAnchor
              href={Routes.documentation}
              style="transparent"
              target="_blank"
              className="w-48 h-10 px-4"
            >
              View Documentation{" "}
              <span className="inline-block animate-bounce-x">→</span>
            </GeneralAnchor>
          </div>
          <div className=" mt-[147px]" />
          <span className="text-hmio-black-400 font-Inter p-lg">
            TRUSTED BY
          </span>
          <div className="mt-10" />
          <Marquee />
          <div className="mt-24" />
        </section>
      </Section>
      <Section className="px-0 md:px-6">
        <div className="flex flex-col md:flex-row bg-white rounded-[32px] px-6 py-12 max-w-[1240px] md:m-auto md:justify-center md:items-center">
          {/*
          <SyntaxHighlighter
            style={lioshi}
            showLineNumbers={true}
            language={"bash"}
            wrapLines={true}
            className="overflow-auto rounded-lg grow md:max-w-[480px] md:min-w-[350px]"
          >
            {DisplayedDeveloperCode}
          </SyntaxHighlighter>
  */}
          <DeveloperCodeBlock />
          <div className="md:w-[108px] mt-8 md:mt-0" />
          <div className="max-h-[253px] w-[480px] shrink">
            <div className="text-3.5xl text-hmio-black font-bold font-Inter flex flex-col min-w-[245px]">
              <span>For developers,</span>
              <span>by developers</span>
            </div>
            <div className="mt-8" />
            <div className="w-480">
              <p className="break-words max-w-[370px] text-base text-hmio-black-500 font-medium">
                Easily integrate real-time data to your product in just a few
                minutes
              </p>
            </div>
            <div className="mt-8" />
            <GeneralAnchor
              href={Routes.documentation}
              style="transparent"
              target="_blank"
              className="p-0 bg-white cursor-pointer p1 text-hmio-blue-500 hover:opacity-80"
            >
              View Documentation{" "}
              <span className="inline-block animate-bounce-x">→</span>
            </GeneralAnchor>
          </div>
        </div>
      </Section>
      <Section className="text-hmio-black-100 mt-14">
        <div className="mb-6">
          <h2 className="font-bold mb-8 lg:pl-6 m-auto max-w-[1008px] text-3.5xl">
            Powerful, easy to use developer tools
          </h2>
          <MobileCarousel className="lg:hidden" slides={toolsSlides} />
          <DesktopCarousel className="hidden lg:block" slides={toolsSlides} />
        </div>
      </Section>
      <Section className="px-0">
        <div>
          <h2 className="font-bold text-hmio-black-100 text-center text-3.5xl mb-10">
            What people say about us
          </h2>
          <Rotisserie
            slides={tweets.map((t, idx) => (
              <Tweet key={idx} tweet={t} />
            ))}
          />
        </div>
      </Section>
      <Section>
        {/* TODO: stop making one-offs */}
        <div className="max-w-[984px] m-auto">
          <h2 className="text-hmio-black-100 text-3.5xl font-bold mb-14">
            Flexible pricing that scales with you
          </h2>
          <PricingPlans />
        </div>
      </Section>
      <Section>
        <h2 className="text-hmio-black-100 font-bold text-[2rem] leading-[3rem] text-center mb-12">
          Join the community
        </h2>
        <div>
          <ul className="flex justify-center gap-10">
            <li>
              <Anchor to={"https://twitter.com/HelloMoon_io"} target="_blank">
                <Button
                  style="primary"
                  className="flex items-center justify-center w-20 h-20"
                >
                  <TwitterLogo
                    className="fill-white"
                    height="32"
                    width="32"
                    viewBox="0 0 24 24"
                  />
                </Button>
              </Anchor>
            </li>
            <li>
              <Anchor to={"https://discord.gg/HelloMoon"} target="_blank">
                <Button
                  style="primary"
                  className="flex items-center justify-center w-20 h-20"
                >
                  <DiscordLogo
                    className="fill-white"
                    height="32"
                    width="32"
                    viewBox="0 0 24 24"
                  />
                </Button>
              </Anchor>
            </li>
          </ul>
        </div>
      </Section>
      <div dangerouslySetInnerHTML={{ __html: "<!-- CI Version -->" }} />
    </>
  );
};

DevelopersPage.getLayout = function getLayout(page: ReactElement) {
  return (
    <Layout dataBar={false} tempSpacer={false}>
      {page}
    </Layout>
  );
};

export default DevelopersPage;
