import * as AspectRatio from "@radix-ui/react-aspect-ratio";
import { useState } from "react";
import { CarouselProps } from "./types";
import { useSwipeable } from "react-swipeable";
import Image from "next/image";

/** Modeled after iOS carousel. Designed for Developer landing page spec, but reusable */
export default function MobileCarousel({ slides, className }: CarouselProps) {
  // Slide index
  const [current, setCurrent] = useState<number>(0);

  const swipeHandlers = useSwipeable({
    onSwipedRight: () => setCurrent(Math.max(0, current - 1)),
    onSwipedLeft: () => setCurrent(Math.min(slides.length - 1, current + 1)),
    preventScrollOnSwipe: true,
  });

  return (
    <div className={className}>
      <div className="overflow-hidden w-full" {...swipeHandlers}>
        <div
          className="flex flex-nowrap gap-2"
          style={{
            transform: `translateX(calc((100% * ${-current}) + 4px))`,
            transition: `transform 300ms`,
          }}
        >
          {slides.map((slide, idx) => (
            <div
              key={`slide-${idx}`}
              className="shrink-0"
              style={{ width: `calc(100% - 0.5rem)` }}
            >
              <figure className="w-full rounded-2xl overflow-hidden mb-8">
                {/* Preserves the aspect ratio of the images we intend on displaying, adjusting size as needed */}
                <AspectRatio.Root
                  className={
                    !slide.imageUrl ? "bg-hmio-black-600" : "bg-transparent"
                  }
                  ratio={1 / 1}
                >
                  {slide.imageUrl ? (
                    <Image src={slide.imageUrl} alt="" layout="fill" />
                  ) : null}
                </AspectRatio.Root>
              </figure>
              <h3 className="mb-4">{slide.title}</h3>
              <p className="text-hmio-black-400 font-medium">
                {slide.description}
              </p>
            </div>
          ))}
        </div>
      </div>
      <div className="mt-6">
        <ol className="flex justify-center gap-2 items-center">
          {slides.map((_val, idx) => (
            <li key={`control-${idx}`} className="flex">
              <button
                onClick={() => setCurrent(idx)}
                className={`rounded-full h-3 w-3 ${
                  current == idx ? "bg-hmio-blue" : "bg-hmio-black-700"
                }`}
              />
            </li>
          ))}
        </ol>
      </div>
    </div>
  );
}
