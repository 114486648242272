import { useEffect, useState } from "react";
import useMediaQuery from "src/hooks/useMediaQuery";
import ArrowRight from "src/icons/V2/Utility/Arrow-Right.svg";

const DEFAULT_TRANSITION_MS = 300;
// 🐔
export default function Rotisserie({ slides }: { slides: JSX.Element[] }) {
  // Slide index
  const [current, setCurrent] = useState<number>(0);

  const isLarge = useMediaQuery("(min-width: 528px)");

  // Desktop slide size
  const slideSize = "480px";
  const desktopFormula = `translateX(
              calc(
                ((${slideSize} + 24px) * ${-(current + 1)})
                - ((${slideSize} / 2) + (24px * 2))
                + (50vw - (${slideSize} - (24px)))
              ))`;

  const mobileFormula = `translateX(calc(((100% - 24px) * ${-(
    current + 2
  )}) + 24px))`;

  const roulette = [
    slides[slides.length - 2],
    slides[slides.length - 1],
    ...slides,
    slides[0],
    slides[1],
  ];

  const [transitionMs, setTransitionMs] = useState<number>(
    DEFAULT_TRANSITION_MS,
  );

  // Hack to get the media query to update on first mobile load
  useEffect(() => {
    const hack = async () => {
      const prev = current;
      setCurrent(prev - 1);
      await new Promise((r) => setTimeout(r, 10));
      setCurrent(prev);
    };
    hack();
  }, [isLarge]);

  return (
    <div>
      <div className="overflow-hidden w-full">
        <div
          className="flex flex-nowrap gap-6"
          style={{
            transform: !isLarge ? mobileFormula : desktopFormula,
            transition: `transform ${transitionMs}ms`,
          }}
        >
          {roulette.map((slide, idx) => (
            <div
              key={`slide-${idx}`}
              className={`shrink-0 ${
                !isLarge ? "w-[calc(100%-48px)]" : "w-[480px]"
              } md:shrink md:w-auto`}
            >
              {slide}
            </div>
          ))}
        </div>
      </div>
      <div className="mt-6">
        <div className="flex justify-center gap-6 w-full">
          <button
            className="rounded-full h-12 w-12 shadow-inner-border-1 shadow-hmio-black-600 flex justify-center items-center"
            onClick={async () => {
              setTransitionMs(DEFAULT_TRANSITION_MS);
              setCurrent(current - 1);
              if (current < 0) {
                await new Promise<void>((resolve) =>
                  setTimeout(resolve, DEFAULT_TRANSITION_MS),
                );
                setTransitionMs(0);
                setCurrent(slides.length - 1);
              }
            }}
          >
            <ArrowRight className="rotate-180 fill-hmio-white" />
          </button>
          <button
            className="rounded-full h-12 w-12 shadow-inner-border-1 shadow-hmio-black-600 flex justify-center items-center"
            onClick={async () => {
              setTransitionMs(DEFAULT_TRANSITION_MS);
              setCurrent(current + 1);
              if (current > slides.length - 2) {
                await new Promise<void>((resolve) =>
                  setTimeout(resolve, DEFAULT_TRANSITION_MS),
                );
                setTransitionMs(0);
                setCurrent(0);
              }
            }}
          >
            <ArrowRight className="fill-hmio-white" />
          </button>
        </div>
      </div>
    </div>
  );
}
