import ArrowRight from "src/icons/V2/Utility/Arrow-Right.svg";
import Link from "next/link";
import { ReactNode } from "react";
import Image from "next/image";

// Not sure what to about this, whether this is input manually or if we pull
// from Twitter directly, in which case we can use Twitter's API to fill in
// this info... This will work for now.
interface Tweet {
  author: string;
  pfpUrl?: string;
  handle: string;
  content: string;
  reply?: Tweet;
  date?: string;
}

const TWITTER_URL = "https://twitter.com";

// Handle with the '@' symbol
const Callout = (handle: string) => {
  const twitterLink = "@" + handle.match(/(\w)+/g)?.[0] ?? "";
  return (
    <span>
      <Link href={`${TWITTER_URL}/${twitterLink}`} passHref>
        <a className="text-hmio-blue cursor-pointer">{twitterLink}</a>
      </Link>
      {handle.slice(twitterLink.length)}
    </span>
  );
};

// Handle with the '@' symbol
const Hashtag = (handle: string) => (
  <Link
    href={`${TWITTER_URL}/hashtag/${handle.substring(1)}?src=hashtag_click`}
    passHref
  >
    <a className="text-hmio-blue cursor-pointer">{handle}</a>
  </Link>
);

const Content = ({
  content,
  nested,
}: {
  content: string;
  nested?: boolean;
}): JSX.Element => {
  const nodes: ReactNode[] = [];

  let splitByLine = content.split("\n");
  for (let i = 0; i < splitByLine.length - 1; i++) {
    splitByLine[i] += "\n";
  }

  let words = splitByLine.map((lines) => lines.split(" ")).flat();

  words.forEach((word, idx) => {
    switch (word[0]) {
      case "@":
        nodes.push(Callout(word));
        break;
      case "#":
        nodes.push(Hashtag(word));
        break;
      default:
        nodes.push(word);
        break;
    }

    if (idx < words.length) nodes.push(" ");
  });

  return (
    <p className={`${nested ? "text-sm" : "text-xl"} whitespace-pre-line`}>
      {nodes}
    </p>
  );
};

interface TweetProps {
  tweet: Tweet;
  nested?: boolean;
}

export default function Tweet({ tweet, nested }: TweetProps) {
  const { author, handle, content, pfpUrl, reply, date } = tweet;
  return (
    <div
      className={`bg-white rounded-2xl p-6 text-hmio-black w-full ${
        !nested ? "max-w-[480px]  md:w-[480px] h-80 mb-8" : ""
      }`}
    >
      <div className={`flex gap-2 ${nested ? "items-center mb-3" : "mb-8"}`}>
        <figure
          className={`${
            nested ? "h-6 w-6" : "h-10 w-10"
          } bg-hmio-black-600 rounded-full overflow-hidden`}
        >
          {pfpUrl ? (
            <Image
              src={pfpUrl}
              height={nested ? "24" : "40"}
              width={nested ? "24" : "40"}
              alt={`profile picture for ${handle}`}
            />
          ) : null}
        </figure>
        <div className={nested ? "flex gap-2" : ""}>
          <div className={nested ? "font-medium text-sm" : "font-bold"}>
            {author}
          </div>
          <div className="text-hmio-black-650 text-xs">
            {handle} {date && date}
          </div>
        </div>
      </div>
      <Content content={content} nested={nested} />
      {reply && (
        <div className="border-solid border-hmio-black-300 border rounded-2xl mt-6">
          <Tweet tweet={reply} nested />
        </div>
      )}
    </div>
  );
}
